import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { authReducer } from './state/auth/auth.reducer';
import { AuthEffects } from './state/auth/auth.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthRoutingModule } from './auth-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { httpInterceptor } from '../http.interceptor';
import { ToastEffects } from './state/toast/taoast.effects';

export interface AuthModuleConfig {
  loginPath: string;
  // Other configuration properties
}

export const AUTH_MODULE_CONFIG = new InjectionToken<AuthModuleConfig>('AuthModuleConfig');

const DEFAULT_CONFIG: AuthModuleConfig = {
  loginPath: 'login',
};


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects, ToastEffects]),
]
})
export class AuthModule {
  static forRoot(config: AuthModuleConfig): ModuleWithProviders<AuthModule> {
    const mergedConfig = { ...DEFAULT_CONFIG, ...config };

    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AUTH_MODULE_CONFIG,
          useValue: mergedConfig
        },
      ]
    };
  }
}
