import { createReducer, on } from '@ngrx/store';
import {
  logInFailed,
  logInFailedClear,
  logInSuccess,
  registerFailed,
  registerFailedClear,
  registerSuccess,
  restoreLastSessionFailed,
  restoreLastSessionSuccess,
  signOutSuccess
} from './auth.actions';
import { User } from '../../authapi.types';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  loggedIn: boolean | null;
  loginErrorMessage: string;
  registrationStep: 'start' | 'verify' | 'success';
  registerErrorMessage: string;
  me: User;
  loginRedirectUrl: string;
}

export const initialState: AuthState = {
  loggedIn: null,
  loginErrorMessage: '',
  registrationStep: 'start',
  registerErrorMessage: '',
  me: null,
  loginRedirectUrl: null,
};

export const authReducer = createReducer(
  initialState,
  on(restoreLastSessionSuccess, (state, { user }) => ({
    ...state,
    loggedIn: true,
    me: user
  })),

  on(restoreLastSessionFailed, (state, { redirectUrl }) => ({
    ...state,
    loggedIn: false,
    loginRedirectUrl: redirectUrl
  })),

  on(signOutSuccess, (state) => ({
    ...state,
    loggedIn: false,
    loginRedirectUrl: null
  })),

  on(logInSuccess, (state, { }) => ({
    ...state,
    loggedIn: true,
  })),

  on(logInFailed, (state, { message }) => ({
    ...state,
    loginErrorMessage: message
  })),

  on(logInFailedClear, ((state) => ({
    ...state,
    loginErrorMessage: ''
  }))),

  on(registerSuccess, (state, { user }) => ({
    ...state,
    me: user,
    loggedIn: true,
  })),

  on(registerFailed, (state, { message }) => ({
    ...state,
    registerErrorMessage: message
  })),

  on(registerFailedClear, ((state) => ({
    ...state,
    registerErrorMessage: ''
  }))),
);
