import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRouterLink, IonRouterOutlet, IonSpinner, IonSplitPane, IonThumbnail, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store, select } from '@ngrx/store';
import { addIcons } from "ionicons";
import { card, chatboxEllipses, logOut, person, planet, timer } from "ionicons/icons";
import { map, tap } from 'rxjs/operators';
import { signOut } from '../auth/state/auth/auth.actions';
import { loggedInUser } from '../auth/state/auth/auth.selectors';
import { SynapsMediaApiService } from '../synaps-media-api.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.page.html',
    styleUrls: ['./layout.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IonToolbar, IonHeader,
        RouterLink,
        RouterLinkActive,
        NgFor,
        NgIf,
        AsyncPipe,
        IonRouterLink,
        IonSplitPane,
        IonMenu,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        IonMenuToggle,
        IonIcon,
        IonThumbnail,
        IonSpinner,
        IonFooter,
        IonRouterOutlet
    ],
})
export class LayoutPage {
    activeUser$ = this.store.pipe(
        select(loggedInUser),
        tap((user) => console.log('user', user)),
    );

    sites$ = this.api.getSites().pipe(
        map((response) => response.data),
        map((sites) => sites.map((site) => ({
            ...site,
            info: {
              ...site.info,
              logo: site.info.logo?.startsWith('/') ? `${site.info.url}${site.info.logo}` : site.info.logo,
              icon: site.info.icon?.startsWith('/') ? `${site.info.url}${site.info.icon}` : site.info.icon,
              cover_image: site.info.cover_image?.startsWith('/') ? `${site.info.url}${site.info.cover_image}` : site.info.cover_image,
            },
          })),
        ),
    );

    portalUrl = this.api.billingPortalRedirectUrl;

    constructor(
        private store: Store,
        private api: SynapsMediaApiService,
    ) {
        addIcons({ planet, timer, card, person, chatboxEllipses, logOut });
    }

    help() {
      window.$chatwoot.toggle();
    }

    signOut() {
        this.store.dispatch(signOut());
    }
}
