import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

export const loginPath = 'login';
export const registerPath = 'register';
export const verifyPath = 'verify';
export const startFirstSitePath = 'start-new-website';
export const emailConfirmedPath = 'email-confirmed';
export const resetPasswordPath = 'reset-password';

const routes: Routes = [
  {
    path: loginPath,
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: registerPath,
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: verifyPath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: startFirstSitePath,
    canActivate: [AuthGuard],
    loadChildren: () => import('./start-first-site/start-first-site.module').then( m => m.StartFirstSitePageModule)
  },
  {
    path: resetPasswordPath,
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
