import { APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import * as Sentry from "@sentry/angular";

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { AuthModule } from './app/auth/auth.module';
import { httpInterceptor } from './app/http.interceptor';
import Logger from './app/logger.service';
import { projectReducer } from './app/state/project/project.reducer';
import { environment } from './environments/environment';

const log = Logger('rx:actions');
const missingTranslateLog = Logger('translate');


export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    log(action.type, state);

    return reducer(state, action);
  };
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    missingTranslateLog(params);
    return 'some value';
  }
}



Sentry.init({
  dsn: "https://efa2a91d8df25456bdabe9d01966fe2f@o24201.ingest.us.sentry.io/4506949006196736",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: environment.stage,
  enabled: environment.production,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, StoreModule.forRoot({
      project: projectReducer,
    }, { metaReducers: [logger] }), EffectsModule.forRoot([]), TranslateModule.forRoot({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      useDefaultLang: false,
    }), AppRoutingModule, AuthModule),
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(
      withInterceptors([httpInterceptor])
    ),
    provideIonicAngular({ mode: 'ios' }), provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
})
  .catch(err => console.log(err));


declare global {
  interface Window {
    $chatwoot: {
      setUser: (id: string, user: Partial<{
        name: string; // Name of the user
        avatar_url: string; // Avatar URL
        email: string; // Email of the user
        identifier_hash: string; // Identifier Hash generated based on the webwidget hmac_token
        phone_number: string; // Phone Number of the user
        description: string; // description about the user
        country_code: string; // Two letter country code
        city: string; // City of the user
        company_name: string; // company name
        social_profiles: {
          twitter: string; // Twitter user name
          linkedin: string; // LinkedIn user name
          facebook: string; // Facebook user name
          github: string; // Github user name
        },
      }>) => void;
      setCustomAttributes: (attributes: {[key: string]: string | number }) => void;
      deleteCustomAttribute: (key: string) => void;
      setLocale(locale: string): void;
      setLabel(label: string): void;
      removeLabel(label: string): void;
      reset(): void;
      toggle(state?: 'open' | 'close'): void;
      toggleBubbleVisibility(state?: 'show' | 'hide'): void;
      popoutChatWindow(): void;
    };
  }
}
/// <reference types="chatwoot" />
