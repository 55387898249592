import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, timer } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from '../../authapi.service';
import Logger from '../../logger.service';
import { showToast } from '../toast/toast.actions';
import { logIn, logInFailed, logInSuccess, restoreLastSession, restoreLastSessionFailed, restoreLastSessionSuccess, signOut, signOutSuccess } from './auth.actions';
import { NavController } from '@ionic/angular/standalone';
import { environment } from 'src/environments/environment';
import { User } from '../../authapi.types';

const log = Logger('effects:auth');

export const LS_TOKEN_KEY = 'auth';
export const LS_TOKEN_EXPIRE_KEY = 'auth_expire';

@Injectable()
export class AuthEffects {
  restoreLastSession$ = createEffect(() => this.actions$.pipe(
    ofType(restoreLastSession),
    switchMap(() => {
      log('restoreLastSession');

      return this.api.me().pipe(
        tap((user) => log('restored user', user)),
        map((user) => restoreLastSessionSuccess({ user })),
        tap(() => log('session set')),
        catchError((error) => {
          console.error(error);
          let redirectUrl = location.href.replace(location.origin, '');
          if (redirectUrl.startsWith('/login')) {
            redirectUrl = '/'
          }
          return of(restoreLastSessionFailed({ redirectUrl }));
        })
      );

    }),
  ));

  logIn$ = createEffect(() => this.actions$.pipe(
    ofType(logIn),
    mergeMap(({ username, password, redirectUrl }) => this.api.login(username, password)
      .pipe(
        map(({ user }) => logInSuccess({ user, redirectUrl })),
        catchError((error) => {
          log('login failed', error);
          return of(logInFailed({ message: 'Login failed. Please check your e-mail address and password.' }))
        })
      )
    ),
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(logInSuccess),
    tap(({ redirectUrl }) => {
      location.href = redirectUrl || '/';
    }),
  ), { dispatch: false });

  signOut$ = createEffect(() => this.actions$.pipe(
    ofType(signOut),
    switchMap(() => this.api.signOut()),
    tap(() => {
      log('sign out');
      // this.router.navigateByUrl('/login')
      this.navCtrl.navigateRoot('/login');
    }),
    map(() => signOutSuccess()),
  ));

  signOutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(signOutSuccess),
    map(() => {
      log('sign out success');
      return showToast({ message: 'You signed out successfully!', color: 'success' });
    })
  ));

  waitForChatwoot$ = createEffect(() => this.actions$.pipe(
    ofType(logInSuccess, restoreLastSessionSuccess),
    switchMap(({ user }) => {
      return new Observable((observer) => {
        const interval = setInterval(() => {
          if (window.$chatwoot) {
            clearInterval(interval);
            console.log('chatwoot ready');
            observer.next({ user });
            observer.complete();
          }
        }, 1000);
      });
    }),
    take<{ user: User }>(1),
    tap(({ user }) => {

      window.$chatwoot.setUser(`${environment.stage}-${user.id}`, {
        email: user.email, // Email of the user
        name: user.email, // Name of the user
        // identifier_hash: "", // Identifier Hash generated based on the webwidget hmac_token
      });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private router: Router,
    private navCtrl: NavController
  ) { }

  ngrxOnInitEffects() {

    return restoreLastSession();
  }

}
