import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutPage } from './layout.page';
// import { TawkMessengerAngularModule } from '@tawk.to/tawk-messenger-angular/dist/tawk-messenger-angular';

export const siteRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('../site-home/site-home.module').then( m => m.SiteHomePageModule)
  },
  {
    path: 'domains/add',
    loadChildren: () => import('../add-domain/add-domain.module').then( m => m.AddDomainPageModule)
  },
  {
    path: 'domains/:domainId',
    loadChildren: () => import('../add-domain/add-domain.module').then( m => m.AddDomainPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../site-settings/site-settings.module').then( m => m.SiteSettingsPageModule)
  },

];

const routes: Routes = [
  {
    path: '',
    component: LayoutPage,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then( m => m.DashboardPageModule)
      },
      {
        path: 'sites',
        loadChildren: () => import('../sites/sites.module').then( m => m.SitesPageModule)
      },
      {
        path: 'sites/:siteId',
        children: siteRoutes
      },
      {
        path: 'billing',
        loadChildren: () => import('../billing/billing.module').then( m => m.BillingPageModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('../settings/settings.module').then( m => m.SettingsPageModule)
      },
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    // TawkMessengerAngularModule,
  ],
  exports: [RouterModule],
})
export class LayoutPageRoutingModule {}
