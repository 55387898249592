import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { isLoggedIn } from "./auth/state/auth/auth.selectors";
import { environment } from "src/environments/environment";
import { Observable, catchError, filter, map, of, shareReplay, switchMap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ListResponse, SingleResponse, Site, SiteCreateResponse, SiteDomain, SiteIncludeOptions, SubscriptionRenewResponse } from "./synaps-space.types";


@Injectable({
  providedIn: 'root',
})
export class SynapsMediaApiService {
  loggedInUser = this.store.pipe(
    select(isLoggedIn),
    shareReplay(),
  );
  apiUrl = environment.apiUrl;

  constructor(private store: Store, private http: HttpClient) {

  }

  billingPortalRedirectUrl = `${this.apiUrl}/users/me/customer-portal?redirect=1`;

  getBillingPortalUrl() {
    return this.request(`${this.apiUrl}/users/me/customer-portal`, 'GET', null);
  }

  checkServiceNameAvailability(serviceName: string): Observable<boolean> {
    console.log('checking service name availability', serviceName);

    return this.request(`${this.apiUrl}/sites/${serviceName}`, 'HEAD', null).pipe(
      map(() => false),
      catchError(() => of(true))
    );
  }

  createSite(serviceName: string) {
    return this.request<SiteCreateResponse>(`${this.apiUrl}/sites`, 'POST', {
      serviceName
    });
  }

  getSites(include?: SiteIncludeOptions[]) {
    return this.request<ListResponse<Site>>(`${this.apiUrl}/sites${include ? `?include=${include.join('&include=')}` : '' }`, 'GET', null);
  }

  getSite(siteId: string, include?: SiteIncludeOptions[]) {
    return this.request<Site>(`${this.apiUrl}/sites/${siteId}${include ? `?include=${include.join('&include=')}` : '' }`, 'GET', null);
  }

  getSiteDomains(siteId: number | string) {
    return this.request<ListResponse<SiteDomain>>(`${this.apiUrl}/sites/${siteId}/domains`, 'GET', null);
  }

  getSitePaymentUrl(siteId: string): Observable<string> {
    return this.request(`${this.apiUrl}/sites/${siteId}/payment`, 'GET', null).pipe(
      map((response: any) => response.result)
    );
  }

  renewSubscription(siteId: string) {
    return this.request<SubscriptionRenewResponse>(`${this.apiUrl}/sites/${siteId}/subscription`, 'PATCH', { action: 'renew' });
  }

  purgeSiteCache(siteId: string): Observable<any> {
    return this.request(`${this.apiUrl}/sites/${siteId}/cache`, 'DELETE', null).pipe(
      map((response: any) => response.result)
    );
  }

  addDomain(siteId: string, domain: string, main: boolean) {
    return this.request<SingleResponse<SiteDomain>>(`${this.apiUrl}/sites/${siteId}/domains`, 'POST', { domain, main });
  }

  checkDomain(siteId: string, domain: string) {
    return this.request(`${this.apiUrl}/sites/${siteId}/domains/check`, 'POST', { domain });
  }

  getSiteDomain(siteId: string|number, domainId: string|number) {
    return this.request<SingleResponse<SiteDomain>>(`${this.apiUrl}/sites/${siteId}/domains/${domainId}`, 'GET', null);
  }

  updateDomain(siteId: string|number, domainId: string|number, main: boolean) {
    return this.request(`${this.apiUrl}/sites/${siteId}/domains/${domainId}`, 'PATCH', { main });
  }

  deleteDomain(siteId: string|number, domainId: string|number) {
    return this.request(`${this.apiUrl}/sites/${siteId}/domains/${domainId}`, 'DELETE', null);
  }

  private request<T>(url: string, method: string, body: any): Observable<T> {
    return this.loggedInUser.pipe(
      filter(Boolean),
      switchMap(() => {
        return this.http.request<T>(method, url, {
          body,
        });
      })
    );
  }
}
