import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  AuthResponse,
  User
} from './authapi.types';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  me(): Observable<User> {
    return this.http.get(`${this.baseUrl}/users/me`).pipe(
      map((res) => res as User)
    );
  }

  register(email, password) {
    console.log(email, password);
    return this.http.post<AuthResponse>(`${this.baseUrl}/users`, {
      email,
      password,
    }).pipe(
      tap((resp) => { console.log(resp); }),
    );
  }

  verifyToken(code) {
    return this.http.put(`${this.baseUrl}/users/me/verify`, {
      code,
    });
  }

  resendToken() {
    return this.http.post(`${this.baseUrl}/users/me/verify`, {});
  }

  /**
   * Password reset
   *
   * @param email Email address to send password reset
   */
  passwordReset(email) {
    return this.http.delete(`${this.baseUrl}/users/me/password`, {
      params: {
        email,
      },
    });
  }

  createNewPassword(token, newPassword) {
    return this.http.post(`${this.baseUrl}/users/me/password`, {
      token,
      newPassword,
    });
  }

  checkPassword(currentPassword: string) {
    return this.http.post(`${this.baseUrl}/users/me/password/check`, {
      currentPassword,
    });
  }

  changePassword(newPassword) {
    return this.http.put(`${this.baseUrl}/users/me/password`, {
      newPassword,
    });
  }

  login(email, password) {
    return this.http
      .post<AuthResponse>(`${this.baseUrl}/sessions`, {
        email,
        password,
      });
  }

  signOut() {
    return this.http.delete(`${this.baseUrl}/sessions`);
  }
}
